import React from "react"
import GetStarted from "./getStarted"

import styles from '../styles/indexPage.module.css'

export default function IndexPage() {
    return (
        <div className={styles.homescreenWrapper}>
                <div className={styles.homeFinderWrapper}>
                    <div className={'sectionWrapper1200'}>
                    	<div className={styles.homeFinder}>
	                    	<div>
                    			<img src={'/images/emailFinder.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                    		</div>
                    		<div>
                    			<h1>Poirot helps you collect thousands of leads in a month by accelerating your prospect email search and reducing the cost per lead.</h1>
                    			<h2>Just input your prospect's first and last name and either target company name or URL, and Poirot will provide a relevant verified email address in seconds.</h2>
                    		</div>
                    	</div>
                	</div>
            	</div>
            	<div className={styles.homeCustomersWrapper}>
                    <div className={'sectionWrapper1200'}>
                    	<div className={styles.homeCustomers}>
		                	<div>
                    			<h1>The ideal solution for teams and individuals in</h1>
                    			<div className={styles.homeCustomersWithIcons}>
                                    <div>
                                        <img src={'/images/marketing-icon.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                                        <li>Sales & Marketing</li>
                                    </div>
                    				<div>
                                        
                                        <img src={'/images/human-resources-icon.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                                        <li>Human Resources</li>
                                    </div>
                                    <div>
                        				
                                        <img src={'/images/public-relation.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                                        <li>Public Relations</li>
                                    </div>
                                    <div>
                        				
                                        <img src={'/images/business-development-icon.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                                        <li>Business Development</li>
                                    </div>
                                    <div>
                        				
                                        <img src={'/images/recruitment-icon.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                                        <li>Recruiting</li>
                                    </div>
                    			</div>
                    		</div>
                    	</div>
                    </div>
                </div>
                <div className={styles.homePayVerifiedOnlyWrapper}>
	                <div className={'sectionWrapper1200'}>
	                	<div className={styles.homePayVerifiedOnly}>
	                		<div>
	                			<svg className="homePayVerifiedOnlyMark" height="84.143" viewBox="0 0 84.076 84.143" width="84.076" xmlns="http://www.w3.org/2000/svg"><path d="m18.243 73.682h9.424c.879 0 1.563.195 2.246.879l6.69 6.689c3.808 3.857 7.031 3.857 10.889 0l6.689-6.689c.684-.684 1.318-.879 2.246-.879h9.424c5.469 0 7.764-2.295 7.764-7.764v-9.424c0-.928.244-1.562.927-2.246l6.641-6.689c3.857-3.858 3.857-7.08 0-10.938l-6.641-6.689c-.683-.684-.927-1.319-.927-2.246v-9.424c0-5.469-2.295-7.764-7.764-7.764h-9.424c-.928 0-1.562-.195-2.246-.879l-6.689-6.689c-3.858-3.907-7.081-3.907-10.938 0l-6.641 6.689c-.683.635-1.367.879-2.246.879h-9.424c-5.517 0-7.763 2.295-7.763 7.764v9.424c0 .927-.293 1.562-.928 2.246l-6.641 6.689c-3.906 3.858-3.857 7.08 0 10.938l6.641 6.689c.635.684.928 1.318.928 2.246v9.424c0 5.469 2.246 7.764 7.763 7.764zm19.678-13.282c-.683 0-1.22-.293-1.758-.927l-11.181-13.135c-.293-.44-.537-.928-.537-1.416 0-.928.781-1.66 1.66-1.66.635 0 1.074.195 1.562.83l10.205 12.06 18.799-29.492c.391-.586.977-.928 1.514-.928.879 0 1.709.684 1.709 1.612 0 .39-.195.879-.44 1.269l-19.824 30.86c-.439.586-1.025.927-1.709.927z" fill="#a4d037"/></svg>
	                		</div>
	                		<div>
	                			<h1>We only charge for verified email addresses.</h1>
	                			<h2>Every located email address is tested and verified. We want to make sure your email won't bounce. Addresses from untrusted or unverifiable sources will be marked as unverified and free of charge. Even better, when domain search is used, we charge a single credit for each block of up to 10 verified email addresses returned.</h2>
	                		</div>
	                	</div>
	                </div>
                </div>
                <div className={styles.homeTeamWorkWrapper}>
                	<div className={'sectionWrapper1200'}>
                		<div className={styles.homeTeamWork}>
                			<div>
                				<h1>Manage your teams in lead generation endeavors.</h1>
                				<h2>Create and organize multiple teams. Invite and supervise your team members. Set lookup and verification limits to control each team's credit usage.</h2>
                			</div>
                			<div>
                				<img src={'/images/manageTeams.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                			</div>
                		</div>
                	</div>
                </div>
                <div className={styles.homeApiCodeWrapper}>
                	<div className={'sectionWrapper1200'}>
                		<div className={styles.homeApiCode}>
                			<div>
                				<h1>Easily integrate Poirot functionality in your workflow and applications with our API.</h1>
                				<h2>Send lookup and verification task requests to Poirot API and receive results using predefined webhook endpoint or fetch them using regular HTTP GET requests.</h2>
                			</div>
                			<div>
                				<img src={'/images/apiCodeRequest.svg'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                			</div>
                			<div>
                				<img src={'/images/apiCodeResponse.svg'} alt="Poirot Email Lookup Tool - https://poirot.app"/>
                			</div>
                		</div>
                	</div>
                </div>
                <hr />
                <GetStarted />
        </div>
    )
}