import React, { Component } from "react"

import styles from "../styles/header.module.css"

let settings = require("../settings")


class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: "",
		}

		this.handleInputChange = this.handleInputChange.bind(this)
	}

	handleInputChange(event) {
		const target = event.target
		var value = target.value
		const name = target.name

		this.setState({
			[name]: value,
		})
	}
	render() {
		return (
			<div className={styles.headerBlockWrapper}>
				<div className="sectionWrapper1200">
					<div className={styles.headerBlock}>
						<div className={styles.headerAction}>
							<div className={styles.headerMessage}>
								<h1>Your Ideal Prospects Are One Click Away.</h1>
								<h2>
									Stop wasting time. Start collecting validated contacts for your outreach campaigns.
								</h2>
							</div>
							<div className={styles.headerActionButton}>
								<a href={settings.APP_HOST + 'register'}>
									<button className={styles.actionButton}>Get started - it's free</button>
								</a>
							</div>
						</div>
						<div className={styles.headerHero}>
							<div className={styles.headerImage}><img className={styles.footerPoirotLogo} src={'/images/headerScreenshot.png'} alt="Poirot Email Lookup Tool - https://poirot.app"/></div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Header
