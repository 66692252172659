import React from "react"

import styles from '../styles/getStarted.module.css'

let settings = require('../settings')

export default function GetStarted() {

    return (
        <div className={styles.getStartedBlockWrapper}>
        	<div className="sectionWrapper1100">
        		<div className={styles.getStarted}>
                    <div>
                        <h1>Get started with Poirot today.</h1>
                        <h2>Register your free account and put into action 20 email address searches and 100 verifications each month.</h2>
                    </div>
                    <div>
                        <a href={settings.APP_HOST + 'register'}>
                            <button className={styles.actionButton}>
                                Sign up for free
                            </button>
                        </a>                        
                    </div>
                </div>
            </div>
        </div>
    )
}