import React from "react"
import axios from "axios"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import IndexPage from "../components/indexPage"

let settings = require("../settings")
const queryString = require("query-string")

export default function HomePage({ location }) {
	const parsed = queryString.parse(location.search)

	if (typeof parsed.ref !== "undefined") {

		function getCookie(cname) {
			var name = cname + "="
			var ca = document.cookie.split(";")
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i]
				while (c.charAt(0) === " ") {
					c = c.substring(1)
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length)
				}
			}
			return ""
		}

		let cookie = getCookie("poirot_ref")
		if (cookie === "") {
			
			if (parsed.ref.length === 12) {
				const requestCredentials = {
					link: parsed.ref,
				}

				axios
					.post(settings.API_HOST + "user/referral", requestCredentials)
					.then(function (response) {
						if (response.status === 200) {
							var d = new Date()
							d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000)
							var expires = ";expires=" + d.toUTCString() + ";"
							document.cookie =
								"poirot_ref=" + parsed.ref + expires + "domain=.poirot.app"
						} else {
							return
						}
					})
					.catch(function (error) {
						return
					})
			}
		}
	}

	return (
		<Layout isNavigationTransparent={true} isHomePage={true}>
			<SEO
				isHomePage={true}
				title="Poirot"
				description="Instantly find anyone's email address. Backed by industry-leading email lookup & verification technology, Poirot helps your sales and lead generation efforts."
				keywords={[
					`Poirot`,
					`Email Lookup Tool`,
					`Email Finder`,
					`Email Checker`,
				]}
			/>
			<Header />
			<IndexPage />
		</Layout>
	)
}
